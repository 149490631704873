<script>
import _ from "lodash";
import { mapActions } from "vuex";
import Chart from "./Chart";
import { getAiringDays } from "@/util";
const order = getAiringDays();

export default {
	name: "AiringChart",
	extends: Chart,
	data() {
		return {
			dayKeys: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
		};
	},
	computed: {
		airingSchedules() {
			return this.$store.getters
				.chart("airing")
				.map(id => this.$store.getters.entity("airing", id));
		},
		chart() {
			return this.airingSchedules.map(schedule => schedule.media);
		},
		chartByFormat() {
			if (this.airingSchedules[0]) {
				this.afterEnter();
			}

			const groupedSchedules = _.groupBy(this.airingSchedules, schedule => {
				return new Date(schedule.airingAt * 1000).getDay();
			});
			const orderedSchedules = order.map(day => ({
				name: this.dayKeys[day],
				episodes: groupedSchedules[day] || []
			}));

			const chart = {};
			orderedSchedules.forEach(schdule => {
				chart[schdule.name] = schdule.episodes.map(schdule => schdule.media);
			});
			return chart;
		}
	}
};
</script>
