<template>
	<component :is="view" />
</template>

<script>
import Settings from "@/util/settings";
import AiringCalendar from "@/views/AiringCalendar";
import AiringChart from "@/views/AiringChart";

export default {
	components: {
		calendar: AiringCalendar,
		chart: AiringChart
	},
	computed: {
		view() {
			if (this.$store.state.screen.isMobile) {
				return "calendar";
			}
			return this.$store.state.airingView;
		}
	},
	beforeMount() {
		this.fetchAiring();
	},
	beforeRouteUpdate(to, from, next) {
		this.fetchAiring();
		next();
	},
	methods: {
		async fetchAiring() {
			await this.$store.dispatch("fetchAiring");
		}
	},
	metaInfo() {
		const title = `AniChart: Airing Anime Calendar`;
		const description = `Keep track of the airing dates and times of all the anime you're following with the AniChart airing anime calendar. Know exactly when an episode airs!`;
		return {
			title,
			meta: [
				{
					name: "description",
					content: description
				},
				{
					property: "og:title",
					content: title
				},
				{
					property: "og:description",
					content: description
				},
				{
					property: "og:url",
					content: `https://anichart.net${this.$route.path}`
				},
				{
					property: "twitter:title",
					content: title
				},
				{
					property: "twitter:description",
					content: description
				}
			]
		};
	}
};
</script>
