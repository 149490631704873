<template>
	<div class="airing-card" v-if="!hidden">
		<a :href="url" target="_blank" rel="noopener noreferrer">
			<img
				class="image"
				:src="media.coverImage.extraLarge"
				:class="{ loaded: imageLoaded }"
				@load="onImageLoad"
			/>
			<div class="content">
				<div class="title">{{ title }}</div>
				<div class="airing">{{ airingContext }}</div>
				<div class="airing-next" v-if="nextAiring">Airing Next</div>
				<div class="airing-now" v-if="currentlyAiring">Airing Now</div>
			</div>
		</a>
		<highlighter :id="media.id" @hide-card="setHighlightHidden" />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Settings from "@/util/settings";
import Highlighter from "./Highlighter";

const timeFormatOptions = {
	hour: "2-digit",
	minute: "2-digit"
};

export default {
	props: ["schedule", "nextAiring"],
	components: {
		Highlighter
	},
	data() {
		return {
			imageLoaded: false,
			hiddenViaHighlight: false
		};
	},
	computed: {
		...mapGetters(["getTime"]),
		media() {
			return this.$store.getters.entity("media", this.schedule.media);
		},
		url() {
			return Settings.getMediaUrl(this.media);
		},
		title() {
			return Settings.getMediaTitle(this.media);
		},
		hidden() {
			return this.media.isAdult || this.media.format === "MOVIE" || this.hiddenViaHighlight;
		},
		airingContext() {
			const pastAiring = this.getTime > this.schedule.airingAt;
			const time = new Intl.DateTimeFormat("en-US", timeFormatOptions).format(
				this.schedule.airingAt * 1000
			);

			return `Ep ${this.schedule.episode} ${pastAiring ? "aired" : "airing"} at ${time}`;
		},
		currentlyAiring() {
			const now = this.getTime;
			const startTime = this.schedule.airingAt;
			const mediaDuration = this.media.duration || 24;
			const endTime = startTime + mediaDuration * 60;
			return now > startTime && now < endTime;
		}
	},
	methods: {
		onImageLoad() {
			this.imageLoaded = true;
		},
		setHighlightHidden() {
			this.hiddenViaHighlight = true;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.airing-card {
	background: color(foreground);
	border-radius: 4px;
	box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 20px shadow(shadow, 0.08);
	max-height: 65px;
	margin-bottom: 24px;
	position: relative;
	transition: box-shadow 0.6s ease, color 0.2s ease, transform 200ms ease-in-out;

	&:hover {
		box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 25px shadow(shadow, 0.15);

		.highlighter {
			opacity: 1;
		}
	}

	a {
		display: grid;
		grid-template-columns: 50px auto;
	}
}

.content {
	padding: 10px 14px;
	position: relative;
	min-width: 0;
}

.title {
	font-size: 1.4rem;
	font-weight: 800;
	overflow: hidden;
	padding-bottom: 3px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.airing {
	font-size: 1.3rem;
	font-weight: 600;
	opacity: 0.9;
}

.airing-next,
.airing-now {
	background: color(blue);
	border-radius: 4px;
	top: -14px;
	color: color(white);
	font-size: 1.1rem;
	font-weight: 800;
	padding: 3px 8px;
	position: absolute;
	right: -12px;

	@media (max-width: $size-tablet) {
		padding-top: 5px;
	}
}

.airing-now {
	background: color(purple);
}

.image {
	border-radius: 4px 0 0 4px;
	height: 100%;
	max-height: 65px;
	object-fit: cover;
	object-position: center;
	opacity: 0;
	transition: opacity 300ms;
	width: 100%;

	&.loaded {
		opacity: 1;
	}
}

.highlighter {
	position: absolute;
	right: -8px;
	bottom: -8px;
	opacity: 0;
	transition: opacity 200ms ease-in-out;

	&.active {
		opacity: 1;
	}

	/deep/ {
		&:not(.active) .icon {
			fill: color(foreground);
		}

		.dropdown-menu {
			width: 180px;
		}
	}
}
</style>
