<template>
	<div class="airing-view" :class="{ loaded: nextAiring }">
		<div class="calendar" ref="calendar">
			<div class="day" v-for="(day, key) in sortedCalendar" :key="key">
				<h2>{{ day.name }} <filters v-if="key === 0 && !screen.isMobile" /></h2>
				<div class="cards">
					<airing-card
						v-for="(episode, index) in day.episodes"
						:key="index"
						:schedule="episode"
						:next-airing="nextAiring == episode.media"
					/>
				</div>
			</div>
			<div class="day dummy" />
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import AiringCard from "@/components/AiringCard";
import Filters from "@/components/Filters";
import { getAiringDays } from "@/util";
const order = getAiringDays();

export default {
	components: {
		AiringCard,
		Filters
	},
	data() {
		return {
			dayKeys: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
		};
	},
	computed: {
		...mapState(["screen"]),
		...mapGetters(["getTime"]),
		airingSchedules() {
			return this.$store.getters
				.chart("airing")
				.map(id => this.$store.getters.entity("airing", id));
		},
		calendar() {
			return _.groupBy(this.airingSchedules, schedule => {
				return new Date(schedule.airingAt * 1000).getDay();
			});
		},
		sortedCalendar() {
			const calendar = this.calendar;
			return order.map(day => ({
				name: this.dayKeys[day],
				episodes: calendar[day] || []
			}));
		},
		nextAiring() {
			let episode = this.sortedCalendar[1].episodes.find(
				schedule => schedule.airingAt > this.getTime
			);

			// If there was no episode found we need to check tomorrows airing episodes
			if (episode) {
				return episode.media;
			}

			episode = this.sortedCalendar[2].episodes.find(schedule => schedule.airingAt > this.getTime);
			return episode ? episode.media : false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.airing-view {
	opacity: 0;
	transition: opacity 200ms ease-in-out;

	&.loaded {
		opacity: 1;
	}
}

@media (min-width: $size-tablet) {
	.calendar {
		margin: 0 auto;
		max-width: 2000px;
		width: 80vw;
	}

	.day:first-of-type h2 {
		color: color(white);

		.filters {
			float: right;
		}
	}

	.cards {
		display: grid;
		grid-column-gap: 24px;
		grid-row-gap: 6px;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		justify-content: center;
		min-width: 0;
	}
}

@media (max-width: $size-tablet) {
	.calendar {
		cursor: grab;
		display: grid;
		justify-content: left;
		max-width: 100vw;
		min-height: 100vh;
		overflow-x: scroll;
		transition: transform 200ms ease-in-out;
		padding-left: 5vw;
		grid-column-gap: 20px;
		scroll-snap-type: x mandatory;
		grid-template-columns: repeat(9, 80vw);
	}

	.day {
		margin-bottom: 100px;
		min-width: 0;
		scroll-snap-align: center;

		h2 {
			color: color(text);
			font-size: 1.5rem;
			font-weight: 900;
			letter-spacing: 0.1rem;
			text-align: left;
			text-transform: uppercase;
		}
	}
}
</style>
